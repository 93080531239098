<template>
  <app-layout>
    <validation-observer ref="form">
      <chronic-conditions class="card__section" />
      <smoke class="card__section" :short="true" />
      <vape class="card__section" :short="true" />
      <div v-if="error" v-html="error" class="text-danger" />
      <div class="page__card-footer">
        <button
          class="button button_secondary page__card-footer-button how-about-you__submit"
          :disabled="loading"
          @click="validate(nextHandler)"
        >
          <span>Next</span>
          <span class="icon icon__arrow icon__arrow_right"></span>
        </button>
      </div>
    </validation-observer>
  </app-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ValidateMixin from "@/mixins/validate";
import RouteMixin from "@/mixins/routing";
import AppLayout from "@/components/appLayout";
import ChronicConditions from "@/components/characteristics/chronicCondition";
import { formatErrorsToString } from "@/modules/utils";
import Smoke from "@/components/characteristics/smoke";
import Vape from "@/components/characteristics/vape";
import { saveLog } from "@/modules/logger";
import logVisiting from "@/mixins/logVisiting";

import {
  STORE_UPDATE_CHARACTERISTIC,
  ROUTE_HEALTH_PT_1,
  STORE_SET_PASSED,
  ROUTE_PROFILE_RECORD,
  // ROUTE_EXACERBATION
} from "@/constants";

export default {
  name: "HealthPt1",

  components: { AppLayout, ChronicConditions, Smoke, Vape },

  mixins: [ValidateMixin, RouteMixin, logVisiting],

  data: () => ({
    currentRoute: ROUTE_HEALTH_PT_1,
    nextRoute: ROUTE_PROFILE_RECORD,
    error: null
  }),

  computed: {
    ...mapState({
      loading: "loading",
      chronic_conditions: "chronic_conditions",
      characteristics: ({ characteristics }) => characteristics,
      smoke: ({ characteristics }) => characteristics.smoke,
      vape: ({ characteristics }) => characteristics.vape
    })
  },

  methods: {
    ...mapActions([STORE_UPDATE_CHARACTERISTIC, STORE_SET_PASSED]),

    nextHandler() {
      saveLog("health-form-complete");
      this.saveChars()
        .then(this.handleSetPassed)
        .catch(this.errorHandler);
    },

    handleSetPassed() {
      // this[STORE_SET_PASSED]({[ROUTE_EXACERBATION]: true});

      return this.setPassed();
    },

    saveChars() {
      this.error = null;

      return this[STORE_UPDATE_CHARACTERISTIC]({
        ...this.characteristics,
        smoke: this.smoke,
        vape: this.vape,
        ...this.chronic_conditions
      });
    },

    errorHandler(error) {
      if (!error?.response?.data) return;
      saveLog("health-pt1-error", {error : error.response.data});
      this.error = formatErrorsToString(error.response.data);
    }
  }
};
</script>
